.container {
    width: 60%;
    margin: 20px auto;
    padding: 20px;
    border: 1px solid #ccc;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.row {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px;
}

.col {
    flex: 1;
    padding: 0 10px;
    font-size: 24px; /* 减小字体大小 */
}

.label {
    font-weight: bold;
    font-size: 24px; /* 减小字体大小 */
}

.value {
    text-align: right;
    font-size: 24px; /* 减小字体大小 */
}
